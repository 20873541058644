/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React, { useEffect, useRef, useState } from 'react';

interface TooltipProps {
    content: React.ReactNode;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    trigger?: 'hover' | 'click';
    className?: string;
    children?: React.ReactNode;
    'data-testid'?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
    content,
    placement = 'top',
    trigger = 'hover',
    className = 'bg-background-mid px-2 shadow-tooltip',
    children,
    'data-testid': testId,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const triggerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target as Node) &&
                triggerRef.current &&
                !triggerRef.current.contains(event.target as Node)
            ) {
                setIsVisible(false);
            }
        };

        if (trigger === 'click' && isVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            if (trigger === 'click') {
                document.removeEventListener('mousedown', handleClickOutside);
            }
        };
    }, [isVisible, trigger]);

    const handleMouseDown = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const toggleVisibility = () => {
        if (trigger === 'click') {
            setIsVisible((prev) => !prev);
        }
    };

    const getTooltipPositionClass = () => {
        switch (placement) {
            case 'top':
                return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
            case 'bottom':
                return 'top-full left-1/2 transform -translate-x-1/2 mt-2';
            case 'left':
                return 'right-full top-1/2 transform -translate-y-1/2 mr-2';
            case 'right':
                return 'left-full top-1/2 transform -translate-y-1/2 ml-2';
            default:
                return '';
        }
    };

    const getArrowClasses = () => {
        const baseArrowClass = 'w-2 h-2 bg-background-mid rotate-45 absolute';

        switch (placement) {
            case 'top':
                return `${baseArrowClass} bottom-[-4px] left-1/2 transform -translate-x-1/2`;
            case 'bottom':
                return `${baseArrowClass} top-[-4px] left-1/2 transform -translate-x-1/2`;
            case 'left':
                return `${baseArrowClass} right-[-4px] top-1/2 transform -translate-y-1/2`;
            case 'right':
                return `${baseArrowClass} left-[-4px] top-1/2 transform -translate-y-1/2`;
            default:
                return '';
        }
    };

    const getVisibilityStyle = () => (isVisible ? 'opacity-100' : 'opacity-0');

    return (
        <div className='relative inline-block'>
            <div
                ref={triggerRef}
                className='tooltip-trigger'
                onClick={toggleVisibility}
                onMouseEnter={() => trigger === 'hover' && setIsVisible(true)}
                onMouseLeave={() => trigger === 'hover' && setIsVisible(false)}
            >
                {children}
            </div>

            {isVisible && (
                <div
                    ref={tooltipRef}
                    className={`absolute z-10 p-2 shadow-tooltip ${getTooltipPositionClass()} ${getVisibilityStyle()} ${className}`}
                    data-testid={testId}
                    onMouseDown={handleMouseDown}
                >
                    <div className={getArrowClasses()} />
                    {content}
                </div>
            )}
        </div>
    );
};
