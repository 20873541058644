/******************************************************************************
 Unpublished work. Copyright 2024 Siemens

 This material contains trade secrets or otherwise confidential information
 owned by Siemens Industry Software Inc. or its affiliates (collectively,
 "SISW"), or its licensors. Access to and use of this information is strictly
 limited as set forth in the Customer's applicable agreements with SISW.
******************************************************************************/

const DEBUG = false;

const getComputedStylePropertyValue = (
    element: JQuery<HTMLElement> | Element[],
    propertyName: string,
) => {
    const firstElement = element[0];
    DEBUG && console.log('getComputedStylePropertyValue', propertyName, firstElement.outerHTML);
    const computedStyle = window.getComputedStyle(firstElement);
    DEBUG && console.log('getComputedStylePropertyValue', computedStyle);
    return computedStyle.getPropertyValue(propertyName);
};

const getSvgAttributeValue = (
    element: JQuery<HTMLElement> | Element[],
    selector: string,
    attributeName: string,
) => {
    DEBUG && console.log('getSvgAttributeValue', selector, attributeName, element[0].outerHTML);
    const parser = new DOMParser();

    const doc = parser.parseFromString(element[0].outerHTML, 'image/svg+xml');
    const svgElement = doc.documentElement as unknown as SVGSVGElement;

    const svgPart = svgElement.querySelector(selector);
    return svgPart?.getAttribute(attributeName);
};

const extractCssVariableName = (cssVarString: string): string | null => {
    const regex = /var\(--([^)]+)\)/;
    const match = RegExp(regex).exec(cssVarString);
    return match ? match[1] : null;
};

const getComputedCssVariableValue = (
    element: JQuery<HTMLElement> | Element[],
    variableName: string,
) => {
    return window.getComputedStyle(element[0]).getPropertyValue(`--${variableName}`);
};

const getComputedCssVariableValueFromSvgElement = (
    element: JQuery<HTMLElement> | Element[],
    selector: string,
    attributeName: string,
) => {
    const value = testUtil.getSvgAttributeValue(element, selector, attributeName);
    DEBUG &&
        console.log(
            'getComputedCssVariableValueFromSvgElement',
            value,
            selector,
            attributeName,
            element,
        );
    if (!value) {
        return null;
    }

    const varName = testUtil.extractCssVariableName(value);
    if (!varName) {
        return null;
    }
    return testUtil.getComputedCssVariableValue(element, varName);
};

export const testUtil = {
    getComputedStylePropertyValue,
    getSvgAttributeValue,
    extractCssVariableName,
    getComputedCssVariableValue,
    getComputedCssVariableValueFromSvgElement,
};
