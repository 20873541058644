/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { tailwindCssUtil } from 'utils';
import { PluginContext } from 'webComponent';

import { SCREEN_BREAKPOINTS } from '../../../tailwind.config';

const DEBUG = false;

export const useAppViewPort = () => {
    const { appTop, appLeft, appWidth, appHeight } = React.useContext(PluginContext);

    const computeBreakpoint = React.useCallback((pappWidth: number) => {
        DEBUG && console.debug('computeBreakpoint');
        if (tailwindCssUtil.isInBreakpointRange(pappWidth, SCREEN_BREAKPOINTS.LG)) {
            return SCREEN_BREAKPOINTS.LG;
        }
        if (tailwindCssUtil.isInBreakpointRange(pappWidth, SCREEN_BREAKPOINTS.MD)) {
            return SCREEN_BREAKPOINTS.MD;
        }
        return SCREEN_BREAKPOINTS.SM;
    }, []);

    const currentScreenBreakpoint = React.useMemo(
        () => computeBreakpoint(appWidth),
        [appWidth, computeBreakpoint],
    );

    return {
        appTop,
        appLeft,
        appWidth,
        appHeight,
        currentScreenBreakpoint,
        isSm: currentScreenBreakpoint === SCREEN_BREAKPOINTS.SM,
        isMd: currentScreenBreakpoint === SCREEN_BREAKPOINTS.MD,
        isLg: currentScreenBreakpoint === SCREEN_BREAKPOINTS.LG,
    };
};
