/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const DropDownArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' {...props}>
        <title>Dropdown Arrow Icon</title>
        <path d='M12 6H4L8 10L12 6Z' />
    </svg>
);
