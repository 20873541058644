/******************************************************************************
 Unpublished work. Copyright 2024 Siemens

 This material contains trade secrets or otherwise confidential information
 owned by Siemens Industry Software Inc. or its affiliates (collectively,
 "SISW"), or its licensors. Access to and use of this information is strictly
 limited as set forth in the Customer's applicable agreements with SISW.
 ******************************************************************************/
import type { RegionNameType } from 'types';

interface StartSessionResponse {
    sessionId: string;
    region: RegionNameType;
}

export class StartSessionError extends Error {
    statusCode: number;

    constructor(message: string, statusCode: number) {
        super(message);
        this.statusCode = statusCode;
    }
}

export const startSession = async (apiRootUrl: string, accessToken: string) => {
    const response = await fetch(`${apiRootUrl}/api/v1/session/start`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) {
        throw new StartSessionError('Failed to initialize session', response.status);
    }

    const data = (await response.json()) as StartSessionResponse;
    return {
        ...data,
        statusCode: response.status,
    };
};
