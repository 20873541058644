/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export default {
    getExtractAnalyticsInfo: '/partquest-portal/api/v3/extract/analyticsInfo',
    postSearch: '/partquest-portal/api/v3/search/mpn/lite',
    postNextSearchPage: '/partquest-portal/api/v3/search/nextPage/lite',
    getDownloadKey: '/partquest-portal/api/v3/getDownloadKey',
    postInitCacheForDownload: '/partquest-portal/api/v3/initCacheForDownload',
    postCreateEcadContent: '/partquest-portal/api/v3/create/ecadContent',
    postGetDistributionChainInfo: '/partquest-portal/api/v3/search/getDistributionChainInfo',
};
