/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { usePluginRestart } from 'webComponent/hooks';

import { ErrorMessage } from 'components/molecules/ErrorMessage';

const testIds = {
    errorMessage: 'app-error-boundary-error-message',
};

export { testIds as AppErrorBoundaryTestIds };

const AppErrorBoundaryFallback = () => {
    const restartPlugin = usePluginRestart();

    return (
        <ErrorMessage
            data-testid={testIds.errorMessage}
            className='h-screen'
            resetErrorCallback={restartPlugin}
        />
    );
};

export const AppErrorBoundary = ({ children }: React.PropsWithChildren) => {
    return <ErrorBoundary fallback={<AppErrorBoundaryFallback />}>{children}</ErrorBoundary>;
};
