/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { SearchContext } from 'SearchContextProvider';
import React from 'react';

export const ClearButton = (props: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>) => {
    const { clear } = React.useContext(SearchContext);

    return (
        <div
            {...props}
            className={`cursor-pointer ${props.className ?? ''}`.trim()}
            onClick={clear}
        >
            <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <title>Clear</title>
                <rect
                    width='16'
                    height='16'
                    rx='8'
                    fill='var(--button-background_high_contrast_default)'
                />
                <path
                    d='M8.56066 7.85355L11.7071 4.70711L11 4L7.85355 7.14645L4.70711 4L4 4.70711L7.14645 7.85355L4 11L4.70711 11.7071L7.85355 8.56066L11 11.7071L11.7071 11L8.56066 7.85355Z'
                    fill='var(--icon-default_inverted)'
                />
            </svg>
        </div>
    );
};
