/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';
import { usePluginTheme } from 'webComponent/hooks';

import type { ThemeNameType } from 'types';

interface IThemeRootProps {
    forceTheme?: ThemeNameType;
}

const testIds = {
    root: 'theme-root',
};

export { testIds as ThemeRootTestIds };

export const ThemeRoot = ({ forceTheme, children }: React.PropsWithChildren<IThemeRootProps>) => {
    if (forceTheme) {
        return (
            <div data-theme={forceTheme} data-testid={testIds.root}>
                {children}
            </div>
        );
    }

    const theme = usePluginTheme();

    return (
        <div data-theme={theme} data-testid={testIds.root}>
            {children}
        </div>
    );
};
