/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import styles from '/src/index.css?inline';
import { App } from 'App';
import { defaultTheme } from 'core';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { v6 as uuidv6 } from 'uuid';
import { PluginContextProvider } from 'webComponent/PluginContextProvider';

import { isDevelopment } from 'const';
import type { RegionNameType, ThemeNameType } from 'types';

const DEBUG = false;

interface IPluginProps {
    sessionId?: string;
    region?: RegionNameType;
    theme?: ThemeNameType;
    analyticsDigitalProductExperience?: boolean;
    analyticsProductExcellenceProgram?: boolean;
}

interface IAppGeometry {
    top: number;
    left: number;
    width: number;
    height: number;
}

const testIds = {
    container: 'app-container',
} as const;

export { testIds as pQPluginTestIds };

export const Plugin = ({
    sessionId,
    region,
    theme = defaultTheme,
    analyticsDigitalProductExperience,
    analyticsProductExcellenceProgram,
}: IPluginProps) => {
    const [key, setKey] = React.useState<string>(uuidv6());

    const [currentSessionId, setCurrentSessionId] = React.useState<string | undefined>(sessionId);
    const [currentRegion, setCurrentRegion] = React.useState<RegionNameType | undefined>(region);
    const [appGeometry, setAppGeometry] = React.useState<IAppGeometry | undefined>(undefined);

    const restartPlugin = () => {
        setKey(uuidv6()); // changing key will reset state of App component
    };

    const [rootRefReady, setRootRefReady] = React.useState(false);
    const rootRef = React.useRef<HTMLDivElement>(null);

    const computeGeometry = () => {
        if (!rootRef.current) {
            return;
        }
        const rect = rootRef.current.getBoundingClientRect();

        const geom: IAppGeometry = {
            left: rect.left,
            top: rect.top,
            width: rect.width,
            height: window.innerHeight - rect.top,
        };
        DEBUG && console.debug('Plugin:computeGeometry geom:', geom);
        setAppGeometry(geom);
    };

    const handleWindowResize = () => {
        DEBUG && console.debug('Plugin:handleWindowResize');
        computeGeometry();
    };

    const handleAppResize = (entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
            const { width } = entry.contentRect;
            if (width != appGeometry?.width) {
                DEBUG && console.debug('Plugin:handleAppResize setAppGeometry', width);
                // TODO: define & implement rules for resizing conflicts between container and window
                setAppGeometry((prevGeometry) => {
                    if (prevGeometry) {
                        return { ...prevGeometry, width };
                    }
                    return undefined;
                });
            }
        }
    };

    React.useEffect(() => {
        const resizeObserver = new ResizeObserver(handleAppResize);
        if (rootRef.current) {
            computeGeometry();
            window.addEventListener('resize', handleWindowResize);
            resizeObserver.observe(rootRef.current);
            setRootRefReady(true);
        }

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            if (rootRef.current) {
                resizeObserver.unobserve(rootRef.current);
                resizeObserver.disconnect();
            }
        };
    }, [rootRef.current]);

    // restart plugin when any of auth data changes
    React.useEffect(() => {
        if (sessionId !== currentSessionId) {
            if (isDevelopment) {
                console.debug(
                    'Session ID changed - PartQuest Plugin will be restarted',
                    `"${sessionId}"`,
                );
            }

            setCurrentSessionId(sessionId);
            restartPlugin();
        }

        if (region !== currentRegion) {
            if (isDevelopment) {
                console.debug('Region changed - PartQuest Plugin will be restarted', `"${region}"`);
            }

            setCurrentRegion(region);
            restartPlugin();
        }
    }, [sessionId, region]);

    return (
        <React.StrictMode>
            <div ref={rootRef} data-testid={testIds.container}>
                {/* Must prevent immediate rendering, otherwise rootRef will be null */}
                {rootRefReady && appGeometry && (
                    <>
                        <style type='text/css'>{styles}</style>

                        <PluginContextProvider
                            theme={theme}
                            sessionId={currentSessionId}
                            region={currentRegion}
                            analyticsDigitalProductExperience={analyticsDigitalProductExperience}
                            analyticsProductExcellenceProgram={analyticsProductExcellenceProgram}
                            rootRef={rootRef}
                            appTop={appGeometry.top}
                            appLeft={appGeometry.left}
                            appWidth={appGeometry.width}
                            appHeight={appGeometry.height}
                            restartPlugin={restartPlugin}
                        >
                            <App key={key} />
                        </PluginContextProvider>
                    </>
                )}
            </div>
        </React.StrictMode>
    );
};

class PartQuestPlugin extends HTMLElement {
    static readonly observedAttributes = [
        'session-id',
        'region',
        'theme',
        'analytics-digital-product-experience',
        'analytics-product-excellence-program',
    ];

    private readonly renderElement: HTMLDivElement;
    private root?: ReactDOM.Root;
    private getNormalizedAttribute<T>(name: string) {
        const value = this.getAttribute(name);
        return value === null ? undefined : (value as T);
    }

    private getNormalizedStringAttribute<T extends string>(name: string) {
        const value = this.getAttribute(name);
        return value === null ? undefined : (value as T);
    }

    private getNormalizedBooleanAttribute(name: string) {
        const value = this.getAttribute(name);
        return value === null ? undefined : value === 'true';
    }

    private render() {
        if (!this.root) {
            return;
        }

        const props: IPluginProps = {
            analyticsDigitalProductExperience: this.getNormalizedBooleanAttribute(
                'analytics-digital-product-experience',
            ),
            analyticsProductExcellenceProgram: this.getNormalizedBooleanAttribute(
                'analytics-product-excellence-program',
            ),
            sessionId: this.getNormalizedStringAttribute('session-id'),
            region: this.getNormalizedAttribute<RegionNameType>('region'),
            theme: this.getNormalizedAttribute<ThemeNameType>('theme'),
        };

        this.root.render(<Plugin {...props} />);
    }

    constructor() {
        super();

        this.renderElement = document.createElement('div');
        this.attachShadow({ mode: 'open' }).appendChild(this.renderElement);
    }

    // noinspection JSUnusedGlobalSymbols
    connectedCallback() {
        this.root = ReactDOM.createRoot(this.renderElement);
        this.render();
    }

    // noinspection JSUnusedGlobalSymbols
    disconnectedCallback() {
        this.root?.unmount();
    }

    // noinspection JSUnusedGlobalSymbols
    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
        if (oldValue !== newValue) {
            this.render();
        }
    }
}

if (!customElements.get('partquest-plugin')) {
    customElements.define('partquest-plugin', PartQuestPlugin);
}
