/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { frontendRoot, fullVersion, isDevelopment, semanticVersion } from 'const';

import type { siemensAnalyticsEvents } from './';
import './analytics-sdk.js';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const San: any = (window as any).San;

let status: number;

const MODULE_INITIALIZED = 0;

export interface IInitParameters {
    customerId: string; // ID of the user's tenant taken from the JWT token.
    userId: string; // ID of the user taken from the JWT token.
    productKey: string; // product key from Product Administration page
    userEmail: string; // Email of the user taken from the JWT token.
    environment: string; // Environment
    internalUsage?: boolean; // OPTIONAL - indicate if module should be used with internal or external server (external by default).
    sessionTimeoutInSec?: number; // OPTIONAL - sets module lifetime in seconds, after this time module should be initialized again (12 hours [in seconds] is default).
    productTier?: string; // OPTIONAL - sets of products tier
    gatherPIIData?: boolean; // OPTIONAL - indicate if gather user PII data
}

function init(givenParams: IInitParameters) {
    if (_checkModuleInitialization()) {
        return;
    }

    // Session timeout is expressed in seconds, should be set to 12 hours
    const defaultSessionTimeout = 60 * 60 * 12;
    const defaults = { internalUsage: false, sessionTimeoutInSec: defaultSessionTimeout };
    const params = { ...defaults, ...givenParams };

    San.setCustomerIdentifier(params.customerId);
    San.setUserIdentifier(params.userId);

    San.setProductKey(params.productKey);

    if (params.gatherPIIData) {
        const settingUSerPIIStatus = San.setUserPII(params.userEmail);
        if (settingUSerPIIStatus !== 0) {
            console.warn('Failed to set user PII. Error: ' + settingUSerPIIStatus);
        }
    }

    San.addTag(San.tagCategoryEdition, givenParams.environment);
    San.addTag(San.tagCategoryProductSKU, givenParams.productTier);
    San.addTag(San.tagCategoryComponent, 'Plugin');
    San.addTag(San.tagCategoryDeploymentEnvironment, givenParams.environment);

    San.obfData(true);

    if (isDevelopment) {
        San.obfData(false);
        //San.enableDebug(true);
        //San._piwikLogging(true);
    }

    status = San.initialize(
        'EBSPartQuestPortal',
        semanticVersion,
        fullVersion,
        San.applicationLanguage_EN_US,
        params.internalUsage,
        `${frontendRoot}/libs/siemensAnalytics/piwik.js`,
    );

    if (_checkModuleInitialization()) {
        San.setSessionTimeout(params.sessionTimeoutInSec);
    } else {
        throw San.getLastErrorMessage();
    }
}

function endSession(): void {
    try {
        San.endSession();
    } catch (e) {
        console.error(`Siemens Analytics Service : endSession ${e}`);
    }
}

function disableAnalytics(): void {
    if (!_checkModuleInitialization()) {
        optOut();
    } else {
        endSession();
    }
}

function optOut(): void {
    try {
        San.optOut();
    } catch (e) {
        console.error(`Siemens Analytics Service : optOut ${e}`);
    }
}

function optInProductExcellenceProgram(): void {
    try {
        San.enableOptionalData();
    } catch (e) {
        console.error(`Siemens Analytics Service : optIn optional ${e}`);
    }
}

function optOutProductExcellenceProgram(): void {
    try {
        San.disableOptionalData();
    } catch (e) {
        console.error(`Siemens Analytics Service : optOut optional ${e}`);
    }
}

function optInDigitalProductExperience(): void {
    try {
        San.enableNecessaryData();
    } catch (e) {
        console.error(`Siemens Analytics Service : optIn necessary ${e}`);
    }
}

function optOutDigitalProductExperience(): void {
    try {
        San.disableNecessaryData();
    } catch (e) {
        console.error(`Siemens Analytics Service : optOut necessary ${e}`);
    }
}

const logIssue = (): void => {
    console.warn('Siemens Analytics Module not initialized');
};

function addProductInfo(key: string, value: string | number): void {
    try {
        if (_checkModuleInitialization()) {
            San.addProductInfo(key, value);
        } else {
            logIssue();
        }
    } catch (e) {
        console.error(`Siemens Analytics Service : addProductInfo ${e}`);
    }
}

function logPlace(placeName: string): void {
    try {
        if (_checkModuleInitialization()) {
            San.logPlace(placeName);
        } else {
            logIssue();
        }
    } catch (e) {
        console.error(`Siemens Analytics Service : logPlace ${e}`);
    }
}

function logVisitedPage(pageTitle: string) {
    try {
        if (_checkModuleInitialization()) {
            San.logPageView(pageTitle);
        } else {
            logIssue();
        }
    } catch (e) {
        console.error(`Siemens Analytics Service : logVisitedPage ${e}`);
    }
}

function logEvent(analyticsEvent: siemensAnalyticsEvents.AnalyticsEventType) {
    try {
        if (_checkModuleInitialization()) {
            const event = San.newEvent(analyticsEvent.eventName);
            Object.entries(analyticsEvent.eventData).forEach(([eventKey, eventValue]) => {
                event.addProperty(eventKey, eventValue);
            });

            if (isDevelopment) {
                console.info(`Siemens Analytics Service : logEvent`, analyticsEvent);
            }

            event.log();
        } else {
            logIssue();
        }
    } catch (e) {
        console.error(`Siemens Analytics Service : logEvent ${e}`);
    }
}

function logErrorEvent(
    errorCode: string,
    errorMessage: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stackTrace: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorProperties: any,
) {
    try {
        if (_checkModuleInitialization()) {
            const errorEvent = San.newError(errorCode, errorMessage);
            if (stackTrace) {
                errorEvent.addStackTraceItem(
                    stackTrace.location,
                    stackTrace.library,
                    stackTrace.metadata,
                );
            }
            Object.keys(errorProperties).forEach((commandKey) => {
                errorEvent.addProperty(commandKey, errorProperties[commandKey]);
            });
            errorEvent.log();
        } else {
            logIssue();
        }
    } catch (e) {
        console.error(`Siemens Analytics Service : logErrorEvent ${e}`);
    }
}

function _checkModuleInitialization() {
    return status === MODULE_INITIALIZED;
}

function getUserHashId() {
    return San.getHashUserIdentifier();
}

export const siemensAnalyticsService = {
    init,
    endSession,
    addProductInfo,
    logPlace,
    logVisitedPage,
    logEvent,
    logErrorEvent,
    optOut,
    getUserHashId,
    disableAnalytics,
    optInDigitalProductExperience,
    optOutDigitalProductExperience,
    optInProductExcellenceProgram,
    optOutProductExcellenceProgram,
};
