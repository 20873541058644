/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { useAppViewPort } from 'webComponent/hooks';

import { CloseButton } from 'components/atoms/CloseButton';
import { SupplyChainDrawerContext } from 'components/drawers/SupplyChainDrawer';
import { ComponentVerticalInfo } from 'components/organisms/ComponentVerticalInfo';
import { SupplyChainDetails } from 'components/organisms/SupplyChainDetails';

import { Drawer, DrawerContext, DrawerIds } from '../../molecules/Drawer';

const testIds = {
    main: 'supply-chain-component-main',
    componentInfo: 'supply-chain-component-info',
    content: 'supply-chain-drawer-content',
    details: 'supply-chain-drawer-details',
    closeButton: 'supply-chain-drawer-close-button',
} as const;

export { testIds as supplyChainDrawerTestIds };

export const SupplyChainDrawer = () => {
    const { currentComponent, clear } = React.useContext(SupplyChainDrawerContext);
    const { isOpenDrawer, closeDrawer } = React.useContext(DrawerContext);
    const { appWidth } = useAppViewPort();
    const closeHandler = () => {
        clear();
        closeDrawer(DrawerIds.SupplyChainDetails);
    };

    return (
        <Drawer
            data-testid={testIds.main}
            isOpen={isOpenDrawer(DrawerIds.SupplyChainDetails)}
            onClose={closeHandler}
            width={appWidth - 32}
        >
            <div data-testid={testIds.content} className='h-full'>
                <div className='divide-y divide-solid border-b border-b-gray-300'>
                    <div className='flex'>
                        {currentComponent && (
                            <ComponentVerticalInfo
                                data-testid={testIds.componentInfo}
                                data={currentComponent}
                            />
                        )}

                        <div className='ml-auto p-2 pl-4'>
                            <CloseButton data-testid={testIds.closeButton} onClick={closeHandler} />
                        </div>
                    </div>
                </div>

                {currentComponent && (
                    <SupplyChainDetails
                        data-testid={testIds.details}
                        manufacturerPartNumber={currentComponent.mfrPartNumber}
                    />
                )}
            </div>
        </Drawer>
    );
};
