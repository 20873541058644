/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { ComponentProps } from 'react';

import { PartQuestIcon } from 'assets';
import { Message } from 'components/atoms/Message';

const testIds = {
    contentArea: 'start-message-content-area',
} as const;

export { testIds as StartMessageTestIds };

export const StartMessage = (props: Omit<ComponentProps<typeof Message>, 'children'>) => {
    return (
        <Message {...props}>
            <div
                data-testid={testIds.contentArea}
                className='w-52 text-text-hint_text_empty_screen'
            >
                <PartQuestIcon className='mx-auto mb-1' />
                <h2 className='text-[17px]'>Browse PartQuest Portal</h2>
                <p className='text-[14px] font-normal'>
                    Start your search using Manufacturer Part Numbers only.
                </p>
            </div>
        </Message>
    );
};
