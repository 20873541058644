/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import Cookies from 'js-cookie';
import React from 'react';
import { siemensAnalyticsEvents } from 'services/SiemensAnalyticsService';

import { useGetDownloadKeyQuery, useGetInitCacheForDownloadQuery } from 'api/dragService';
import type { IInitCacheForDownloadRequest, IdndKeys } from 'types/Download';
import type { IPluginData } from 'types/PluginData';

const encodeUrl = (url: string, data: string) => `${url}${encodeURIComponent(data)}`;

const getDragData = (apiRootUrl: string, queryObj: IInitCacheForDownloadRequest) => {
    const dndObj: IdndKeys = {
        ps: [
            {
                x: 'Supplyframe',
                y: queryObj.partToPartition[0].partId,
                z: 'PartQuest',
            },
        ],
        f: 'PADS PRO',
        c: queryObj.downloadKey,
        k: queryObj.uniqueId,
    };

    const callEdx = encodeUrl(`${apiRootUrl}/api/v2/download/parts/edx?x=`, JSON.stringify(dndObj));
    const callLog = encodeUrl(
        `${apiRootUrl}/api/v2/download/tool/spn/report?k=`,
        queryObj.uniqueId,
    );

    const cookieKeysAndValuesArray = Object.entries(Cookies.get()).map(([k, v]) => `${k}=${v}`);
    const cookieHeader = { name: 'Cookie', value: cookieKeysAndValuesArray.join(';') };

    return JSON.stringify({
        source: 'PartQuest',
        urls: [{ edx: callEdx, log: callLog }],
        v2: {
            source: 'PartQuest',
            urls: [
                {
                    edx: {
                        url: callEdx,
                        headers: [cookieHeader],
                    },
                    log: {
                        url: callLog,
                    },
                },
            ],
        },
    });
};

const createAnalyticsEvent = (data: IPluginData) =>
    siemensAnalyticsEvents.createDownloadEvent({
        'CAD Source': data.dataAvailability.source,
        'Part Category': data.category.name,
        Supplier: data.manufacturer.name,
        'Supplier Part Number': data.mfrPartNumber,
    });

export const useDragButton = (data: IPluginData) => {
    const [enabled, setEnabled] = React.useState(false);

    const downloadKeyQuery = useGetDownloadKeyQuery(data?.supplierPartNumber, enabled);

    const queryObj: IInitCacheForDownloadRequest = {
        partToPartition: [
            {
                dataSource: 'Supplyframe',
                partId: data?.supplierPartNumber,
                partition: 'PartQuest',
            },
        ],
        flow: 'PADS PRO',
        downloadKey: downloadKeyQuery.data?.downloadKey,
        uniqueId:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
    };

    useGetInitCacheForDownloadQuery(queryObj, enabled);

    const analyticsEvent = createAnalyticsEvent(data);

    return {
        queryObj,
        setEnabled,
        getDragData,
        analyticsEvent,
    };
};
