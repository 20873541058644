/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import {
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { SearchContext } from 'SearchContextProvider';
import React from 'react';
import { siemensAnalyticsEvents, siemensAnalyticsService } from 'services/SiemensAnalyticsService';
import { useAppViewPort } from 'webComponent/hooks';

import { useGetSearchQuery, useGetSearchQueryManager } from 'api/searchService';
import { Pagination } from 'components/molecules/Pagination';
import type { IPluginData } from 'types/PluginData';

import { prepareColumns } from './searchTableColumns';

const DEBUG = false;
const emptyData: IPluginData[] = [];

export const useSearchTable = () => {
    const { debouncedValue } = React.useContext(SearchContext);
    const { currentScreenBreakpoint } = useAppViewPort();

    const postData = {
        key: 'bbac7d09-118ldbwpe-458h45j9ac-b4hu84b3-5ghj8trf-64bca7daee1e',
        keywords: debouncedValue,
    };

    const queryResult = useGetSearchQuery(postData);
    const queryManager = useGetSearchQueryManager();

    const data = React.useMemo(() => {
        return queryResult?.data?.pages.flatMap((page) => page.parts) || emptyData;
    }, [queryResult?.data]);

    const finalColumnDefs = React.useMemo(() => {
        DEBUG &&
            console.debug(
                'useSearchTable-finalColumnDefs: currentScreenBreakpoint',
                currentScreenBreakpoint,
            );
        return prepareColumns(currentScreenBreakpoint);
    }, [currentScreenBreakpoint]);

    const table = useReactTable({
        data: data,
        columns: finalColumnDefs,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        pageCount: queryResult?.data?.pages.length || 0,
        autoResetPageIndex: false,
        debugAll: false,
        debugColumns: true,
    });

    const prevPage = () => {
        if (table.getCanPreviousPage()) {
            table.previousPage();
        }
    };

    const nextPage = () => {
        if (table.getCanNextPage()) {
            table.nextPage();
        } else {
            queryResult.fetchNextPage();
        }
    };

    React.useEffect(() => {
        table.setPageSize(25);
        table.setPageIndex(table.getPageCount());
    }, [data]);

    React.useEffect(() => {
        table.setPageIndex(0);

        if (debouncedValue.length >= 3) {
            siemensAnalyticsService.logEvent(siemensAnalyticsEvents.createSearchEvent());
        }
    }, [debouncedValue]);

    const lastErroredKeyword = React.useRef<string>();

    React.useEffect(() => {
        if (queryResult.isError) {
            lastErroredKeyword.current = debouncedValue;
        }
    }, [queryResult.isError]);

    React.useEffect(() => {
        // we need to cleanup previous query if it errored, otherwise user will get confusing error
        // message when they type the same keyword again
        if (
            lastErroredKeyword.current !== undefined &&
            lastErroredKeyword.current !== debouncedValue
        ) {
            queryManager.removeQuery(lastErroredKeyword.current);
            lastErroredKeyword.current = undefined;
        }
    }, [debouncedValue]);

    const totalParts = queryResult?.data?.pages[0].totalParts ?? 0;

    const paginationView = (
        <Pagination table={table} totalParts={totalParts} nextPage={nextPage} prevPage={prevPage} />
    );

    return {
        data,
        table,
        prevPage,
        nextPage,
        queryResult,
        paginationView,
    };
};
