/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React, { type InputHTMLAttributes } from 'react';
import { tailwindCssUtil } from 'utils';

const BaseInput = React.forwardRef<
    HTMLInputElement,
    InputHTMLAttributes<HTMLInputElement> & { importantClasses?: string }
>((props, ref) => {
    const internalClassName = `w-full rounded-2 text-notation border-none
            outline outline-1 -outline-offset-1 outline-input-border_default_hover hover:outline-2
            focus:outline-2 focus:-outline-offset-2 focus:outline-input-border_active focus:ring-0
            bg-input-background text-text-default
            ${props.className ?? ''}`;

    const { importantClasses, ...htmlProps } = props;

    const finalClassNameFn = () =>
        importantClasses
            ? tailwindCssUtil.customTwMerge(internalClassName, importantClasses)
            : internalClassName;
    const finalClassName = React.useMemo(finalClassNameFn, [internalClassName, importantClasses]);

    return <input {...htmlProps} className={finalClassName.trim()} type='text' ref={ref} />;
});

export const NormalInput = React.forwardRef<
    HTMLInputElement,
    InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
    const finalClassName = `${props.className ?? ''}`.trim();

    return <BaseInput {...props} className={finalClassName} ref={ref} />;
});

export const DisabledInput = React.forwardRef<
    HTMLInputElement,
    InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
    return (
        <BaseInput
            {...props}
            disabled
            importantClasses={'bg-input-background_disabled text-text-disabled hover:outline-1'}
            ref={ref}
        />
    );
});
