/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { ComponentProps } from 'react';

import { Search24Icon } from 'assets';
import { Message } from 'components/atoms/Message';

const testIds = {
    seachIcon: 'empty-message-search-icon',
    contentArea: 'empty-message-content-area',
} as const;

export { testIds as EmptyMessageTestIds };

export const EmptyMessage = (props: Omit<ComponentProps<typeof Message>, 'children'>) => {
    return (
        <Message {...props}>
            <div
                className='w-60 text-text-hint_text_empty_screen'
                data-testid={testIds.contentArea}
            >
                <Search24Icon className='mx-auto mb-1' data-testid={testIds.seachIcon} />
                <h2 className='text-[16px]'>Your search returned no results</h2>
                <p className='text-[14px] font-normal'>Try to refine your search query</p>
            </div>
        </Message>
    );
};
