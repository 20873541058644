/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';
import { siemensAnalyticsEvents, siemensAnalyticsService } from 'services/SiemensAnalyticsService';

import { AccentedButton } from 'components/atoms/Button';
import { PriceBreakdowns } from 'components/organisms/SupplyChainExtendedRowView/PriceBreakdowns';
import type { KeyedDistributionChainInfoType } from 'types/DistributionChainInfo';

const testIds = {
    main: 'supply-chain-extended-row-view-main',
    logo: 'supply-chain-extended-row-view-logo',
    authorizedStatus: 'supply-chain-extended-row-view-authorized-status',
    retractAll: 'supply-chain-extended-row-view-retract-all',
    part: 'supply-chain-extended-row-view-part',
    partPartHashLabel: 'supply-chain-extended-row-view-part-part-hash-label',
    partManufacturerPartNumber: 'supply-chain-extended-row-view-part-manufacturer-part-number',
    partDistributorPartNumber: 'supply-chain-extended-row-view-part-distributor-part-number',
    partManufacturerName: 'supply-chain-extended-row-view-part-manufacturer-name',
    partDescription: 'supply-chain-extended-row-view-part-description',
    partDescriptionLabel: 'supply-chain-extended-row-view-part-description-label',
    partMinimumOrderQuantity: 'supply-chain-extended-row-view-part-minimum-order-quantity',
    partLeadTime: 'supply-chain-extended-row-view-part-lead-time',
    partContainerType: 'supply-chain-extended-row-view-part-container-type',
    partStock: 'supply-chain-extended-row-view-part-container-stock',
    partPriceBreakdowns: 'supply-chain-extended-row-view-part-price-breakdown',
    partBuyNowButton: 'supply-chain-extended-row-view-part-buy-now-button',
};

export { testIds as SupplyChainExtendedRowViewTestIds };

interface ISupplyChainExtendedRowViewProps {
    data: KeyedDistributionChainInfoType;
    toggleView: () => void;
}

export const SupplyChainExtendedRowView = ({
    data,
    toggleView,
    ...props
}: ISupplyChainExtendedRowViewProps &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'classname'>) => {
    function handleBuyNowCLick(buyNowUrl: string) {
        siemensAnalyticsService.logEvent(siemensAnalyticsEvents.createFindChipsBuyNowEvent());
        window.open(buyNowUrl);
    }

    return (
        <div data-testid={testIds.main} {...props} className='px-2 py-4 text-text-default'>
            {data.distributor.logoUrl !== undefined && (
                <img
                    data-testid={testIds.logo}
                    src={data.distributor.logoUrl}
                    alt={data.distributor.name}
                    className='max-w-[100px] object-scale-down'
                />
            )}

            <div className='mt-2 flex'>
                {data.distributor.authorizedStatus && (
                    <div data-testid={testIds.authorizedStatus} className='text-notation'>
                        Authorized Distributor
                    </div>
                )}

                <button
                    type='button'
                    data-testid={testIds.retractAll}
                    className='ml-auto text-notation font-semibold text-text-link'
                    onClick={toggleView}
                >
                    Retract all
                </button>
            </div>

            {data.parts.map((part) => (
                <div
                    key={part.key}
                    data-testid={testIds.part}
                    className='mt-2 border-t border-table-border pt-2'
                >
                    <div className='grid grid-cols-[78px_1fr] gap-x-4 gap-y-2 text-notation'>
                        {part.manufacturerPartNumber !== undefined && (
                            <>
                                <span data-testid={testIds.partPartHashLabel} className='font-bold'>
                                    Part#
                                </span>
                                <div
                                    data-testid={testIds.partManufacturerPartNumber}
                                    className='font-semibold text-text-link'
                                >
                                    {part.manufacturerPartNumber}
                                </div>
                            </>
                        )}

                        {part.distributorPartNumber !== undefined && (
                            <>
                                <span className='font-bold'>
                                    {!part.manufacturerPartNumber && (
                                        <span data-testid={testIds.partPartHashLabel}>Part#</span>
                                    )}
                                </span>
                                <div>
                                    DISTI #{' '}
                                    <span data-testid={testIds.partDistributorPartNumber}>
                                        {part.distributorPartNumber}
                                    </span>
                                </div>
                            </>
                        )}

                        {part.manufacturerName !== undefined && (
                            <>
                                <span className='font-bold'>Manufacturer</span>
                                <div data-testid={testIds.partManufacturerName}>
                                    {part.manufacturerName}
                                </div>
                            </>
                        )}

                        {(part.description !== undefined ||
                            part.minimumOrderQuantity !== undefined ||
                            part.leadTime !== undefined ||
                            part.containerType !== undefined) && (
                            <>
                                <span
                                    data-testid={testIds.partDescriptionLabel}
                                    className='font-bold'
                                >
                                    Description
                                </span>
                                <div className='grid grid-cols-1 gap-y-0.5'>
                                    {part.description !== undefined && (
                                        <div data-testid={testIds.partDescription}>
                                            {part.description}
                                        </div>
                                    )}

                                    {part.minimumOrderQuantity !== undefined && (
                                        <div>
                                            <span className='font-bold'>Min Qty:</span>
                                            <span
                                                data-testid={testIds.partMinimumOrderQuantity}
                                                className='ml-2 text-text-positive'
                                            >
                                                {part.minimumOrderQuantity}
                                            </span>
                                        </div>
                                    )}

                                    {part.leadTime !== undefined && (
                                        <div>
                                            <span className='font-bold'>Lead time:</span>{' '}
                                            <span
                                                data-testid={testIds.partLeadTime}
                                                className='ml-2 text-text-positive'
                                            >
                                                {part.leadTime}
                                            </span>
                                        </div>
                                    )}

                                    {part.containerType !== undefined && (
                                        <div>
                                            <span className='font-bold'>Lead time:</span>{' '}
                                            <span
                                                data-testid={testIds.partContainerType}
                                                className='ml-2 text-text-positive'
                                            >
                                                {part.containerType}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {part.stock !== undefined && (
                            <>
                                <span className='font-bold'>Stock</span>
                                <div data-testid={testIds.partStock}>{part.stock}</div>
                            </>
                        )}

                        {part.priceBreakdown.length > 0 && (
                            <>
                                <span className='font-bold'>Price</span>
                                <div data-testid={testIds.partPriceBreakdowns}>
                                    <PriceBreakdowns data={part.priceBreakdown} />
                                </div>
                            </>
                        )}

                        {part.buyNowUrl !== undefined && (
                            <div className='col-span-2'>
                                <AccentedButton
                                    data-testid={testIds.partBuyNowButton}
                                    className='ml-auto w-[200px]'
                                    onClick={() => handleBuyNowCLick(part.buyNowUrl!)}
                                >
                                    Buy Now
                                </AccentedButton>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};
