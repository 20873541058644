/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type { ComponentProps } from 'react';

import { CircularProgress } from 'components/atoms/CircularProgress';
import { Message } from 'components/atoms/Message';

const testIds = {
    contentArea: 'fetch-message-content-area',
} as const;

export { testIds as FetchMessageTestIds };

export const FetchMessage = (props: Omit<ComponentProps<typeof Message>, 'children'>) => {
    return (
        <Message {...props}>
            <div data-testid={testIds.contentArea} className={'text-text-hint_text_empty_screen'}>
                <CircularProgress />
                <h2 className='mt-1 text-notation font-semibold'>Fetching Data...</h2>
            </div>
        </Message>
    );
};
