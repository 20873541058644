/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { SearchContext } from 'SearchContextProvider';
import React from 'react';

import { SearchIcon } from 'assets';

const testIds = {
    button: 'search-input-button',
    input: 'search-input-input',
} as const;

export { testIds as SearchInputTestIds };

export const SearchInput = ({
    className,
    ...props
}: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>) => {
    const { value, setValue, setDebouncedValue } = React.useContext(SearchContext);

    const onEnterButton = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            setDebouncedValue(value);
        }
    };

    return (
        <div
            {...props}
            className={`border-1 relative flex rounded-2 border border-input-border_default_hover text-text-default ${className ?? ''}`.trim()}
        >
            <SearchIcon
                data-testid={testIds.button}
                className='absolute right-2 mt-2 cursor-pointer fill-text-default'
                onClick={() => setDebouncedValue(value)}
            />
            <input
                data-testid={testIds.input}
                type='text'
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={onEnterButton}
                placeholder='Start Search using Manufacturer Part Numbers'
                className='focus:input-border_default_hover w-full border-none bg-input-background p-2 text-notation font-normal italic leading-3 outline-none ring-0 hover:ring-2 hover:ring-input-border_default_hover focus:ring-2'
            />
        </div>
    );
};
